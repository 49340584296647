import { Controller } from 'stimulus'

export default class SearchController extends Controller {
  static targets = ['input']

  connect() {
    (() => {
      let timeout
      this.onChange = (event) => {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
          this.executeSearch(event)
        }, 500)
      }

      this.onSubmit = (event) => {
        logger.debug('onSubmit', event)
        event.preventDefault()
        if (timeout) clearTimeout(timeout)
        this.executeSearch(event)
      }
    })()
  }

  // TODO: Add async fetch options to update the page content
  executeSearch() {
    const url = new URL(this.data.get("path"), window.location.origin)

    this.inputTargets.forEach((input) => {
      const key = input.dataset.searchKey || input.name
      const value = this.getValue(input)
      if (value) {
        url.searchParams.set(key, value)
      } else {
        url.searchParams.delete(key)
      }
    })

    if (this.data.get('unique') && url.toString() === window.location.href) return;
    window.location = url.toString()
  }



  getValue(input) {
    // Add more transformations as needed
    switch (input.dataset.transform) {
      case 'downcase':
        return input.value.toLowerCase()
      case 'upcase':
        return input.value.toUpperCase()
      case 'number':
        return Number(input.value)
      case 'boolean':
        const value = input.value.trim().toLowerCase()
        if (!value || value === 'false' || value === '0') return false
        return !!value
      default:
        return input.value
    }
  }
}
